<template>
   <app-layout>
<!--        <shop-head>-->
<!--            Merci de remplir les marques référencées dans votre Armoire à Beauté-->
<!--        </shop-head>-->

        <terms-condition />

        <div class="footer text-center py-4">
          <button class="btn btn-lg btn-primary uppercase"
                  @click="submit">
            J'accepte
          </button>
        </div>

   </app-layout>
</template>

<script>
import AppLayout from "@/layout/AppLayout";
// import ShopHead from "@/components/ShopHead";
import TermsCondition from "@/components/TermsCondition";

export default {
    name: "ArmoireTerms",
    components: {/*ShopHead,*/ AppLayout, TermsCondition},

    methods: {

    submit(){
      this.$router.push({name: 'brands'});
    }

  }
}
</script>
