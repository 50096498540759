<template>
  <div class="Btn">
    <button>{{ btnName }}</button>
  </div>
</template>

<script>
export default {
  props: ["btnName"],
};
</script>

<style scoped>
.Btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Btn button {
  background: #77be4e 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  border-radius: 32px;
  padding: 16.5px 32px 16.5px 32px;
  text-align: center;

  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1.56px;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

@media (min-width: 430px) {
  .Btn button {
    padding: 16.5px 62px 16.5px 62px;
  }
}

@media (min-width: 500px) {
  .Btn button {
    font-size: 26px;
  }
}
</style>
