<template>
  <div class="header">
    <p class="mb-2">
      <span class="font-heading text-lg">Bonjour {{ shop.name }},</span><br>
      <slot/>
    </p>
    <div class="actions">
      <router-link :to="{name: 'terms-condition'}" class="btn mb-1">
        <i class="fa-solid fa-arrows-rotate"></i>
        Retour
      </router-link>
      <button class="btn btn-warning ml-1 mb-1" @click.stop="reset()">
        <i class="fa-solid fa-arrows-rotate"></i>
        Recommencer le relevé
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ShopHead",


  computed: {
    ...mapGetters([
      'shop',
    ]),
  },

  methods: {

    ...mapActions([
      'resetProducts'
    ]),

    reset(){
      if( !confirm("Êtes-vous sûr de réinitialiser ? Cela ne peut pas être annulé.") ) return;
      this.resetProducts();
      this.$router.push({name: 'home'});
    },
  }
}
</script>

<style scoped>

.header {
  background-color: #789d92;
  color: white;
  text-align: center;
  padding: 1rem;
  font-size: 1.3rem;
}
</style>
