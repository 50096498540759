<template>
  <app-layout>
    <div class="read-container">
      <p class="bravo font-heading">Bravo</p>
      <p class="smallText">
        Merci d'avoir envoyé vos retours. Vous recevrez par email un fichier qu'il faudra imprimer
        et mettre dans votre colis. Notre équipe reviendra vers vous rapidement avec votre
        étiquette d'expédition.
      </p>
    </div>
    <vue3-lottie animation-link="/check-animation.json" :height="320" :width="320" :loop="false" />
    <page-btn btnName="ENVOYER UN AUTRE RELEVÉ" @click.prevent.stop="reset()" />
    <div class="logo">
      <a href="https://www.larmoireabeaute.com/">
        <img src="/logo.png" alt="" />
      </a>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layout/AppLayout.vue";
import PageBtn from "@/components/PageBtn.vue";
import {mapActions} from "vuex";
import {Vue3Lottie} from "vue3-lottie";
export default {
  components: {
    Vue3Lottie,
    AppLayout,
    PageBtn,
  },

  methods: {

    ...mapActions([
        'resetAll'
    ]),

    reset(){
      this.resetAll();
      this.$router.push({name: 'home'});
    }
  },

};
</script>

<style lang="scss" scoped>
.bravo {
  font-size: 4rem;
  color: #231f20;
  text-align: center;
  padding-top: 120px;

  @media screen and (min-width: 1024px) {
    font-size: 5.5rem;
  }
}
.smallText {
  text-align: center;
  color: #231f20;
  font-size: 20px;

}
.logo{
    display: flex;
    justify-content: center;
    margin-top: 2rem;

  img{
    max-width: 180px;
    height: auto;
  }
}
</style>
