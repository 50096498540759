<template>
  <app-layout>
    <div class="home-page">
      <div class="cener-pos">
        <div class="logo">
          <img src="/logo.png" alt="" />
        </div>
        <home-form />
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layout/AppLayout.vue";
import HomeForm from "@/components/HomeForm.vue";
export default {
  components: { AppLayout, HomeForm },
};
</script>

<style lang="scss" scoped>
.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cener-pos {
  width: 428px;
  padding-left: 34px;
  padding-right: 34px;
}
.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
  margin-top: 6rem;

  img{
    margin-left: 25px;
    max-width: 230px;
  }
}
</style>
