<template>
  <div class="tnc">
    <h1 class="tnc-heading">
      <span class="tnc-container">
        Attention
      </span>
    </h1>
    <div class="tnc-container">
      <div class="terms-heading">
        <h2>Cher(e) pharmacien(ne),</h2>
        <h3>
          Vous vous apprêtez à faire une relevé de retours. Voici les règles concernant les produits que vous
          pouvez nous retourner:
        </h3>
      </div>
      <ul>
        <li>Seuls les produits en parfait état seront repris (Pack secondaire intact et pas d’étiquettes sur les packs).
          Dans le cas contraire, les produits ne seront ni repris ni remboursés.
        </li>
        <li>Veuillez emballer soigneusement les produits dans un ou plusieurs cartons</li>
        <li>Imprimez le bordereau que vous recevrez en fin de procédure par mail et mettez-le dans le colis</li>
        <li>Une fois la procédure terminée, nous vous contacterons pour connaitre le poids et la taille du/des colis
          afin de vous envoyer les bordereaux de retour.
        </li>
        <li>Utilisez les cartons de la nouvelle saison pour nous retourner les produits de la précédente saison
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
export default {
    name: "TermsCondition",
}

</script>

<style lang="scss" scoped>

.tnc{
  line-height: 1.5;
}

.tnc-heading {
  background-color: black;
  color: white;
  text-align: center;
  font-family: 'Recoleta SemiBold', serif;
  font-size: 2.7rem;
  padding: 2rem 0;
  margin-bottom: 2rem;
}

.terms-heading {
  margin-bottom: 2rem;

  h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: .8rem;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: .8rem;
  }
}


.tnc-container {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

ul {
  margin-bottom: 2rem;
  // padding-left: 2rem;
  list-style: none;

  li{
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    position: relative;

    &:before{
      content: '\203A';
      position: absolute;
      font-weight: bold;
      left: 0;
      top: 0;
    }
  }
}

</style>
