<template>
  <div class="carousel-part" v-if="type !== 'final-list'">
    <carousel :settings="carousel.settings" :breakpoints="carousel.breakpoints">
      <slide v-for="product in allowed_product" :key="product.id" class="slide"  >
        <carousel-item  :product="product" :type="type"/>
      </slide>
      
      <template #addons>
        <navigation class="navigation"/>
      </template>
    </carousel>
  </div>
  <div class="carousel-part" v-else>
    <carousel :settings="carousel.settings" :breakpoints="carousel.breakpoints">
        <slide v-for="product in removedProducts" :key="product.id" class="slide">
            <carousel-item :product="product" :type="type"/>
        </slide>
      <template #addons>
        <navigation class="navigation"/>
      </template>
    </carousel>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import CarouselItem from "@/components/CarouselItem";
import {mapGetters} from "vuex";

export default {
  components: {
    CarouselItem,
    Carousel,
    Slide,
    Navigation
  },

  props: ['products', 'type', ''],

  data() {
    return {
      carousel: {
        settings: {
          itemsToShow: 2.2,
          snapAlign: 'start',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
          420: {
            itemsToShow: 2.5,
            snapAlign: 'start',
          },
          700: {
            itemsToShow: 3.5,
            snapAlign: 'start',
          },
          1024: {
            itemsToShow: 4.5,
            snapAlign: 'start',
          },
          1200: {
            itemsToShow: 6.5,
            snapAlign: 'start',
          },
          1600: {
            itemsToShow: 7.5,
            snapAlign: 'start',
          },
        },
      }
    };
  },

  computed:{
     ...mapGetters([
      'productCount', 'getAllowedProducts'
    ]),
    removedProducts(){
      return this.products.filter(product => this.productCount(product.id) > 0)
    },
    allowed_product(){
      return this.products.filter(p => this.getAllowedProducts.find(a => a === p.id))
    }
  },

  methods: {
    product_count(product){
      return this.productCount( product?.id ?? null );
    },

  },


};
</script>
<style lang="scss" scoped>
.carousel-part{
  background: rgba(black, .05);
}
.slide{
  padding: .3rem;
  user-select: none;
}
.hide{
  display: none;
}
</style>
