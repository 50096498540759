<template>
  <app-layout>

    <div class="armoire-brands">

      <shop-head>
        Merci de remplir les marques que vous souhaitez nous retourner
      </shop-head>

      <div class="container">
        <div v-for="shelf in shelves" :key="shelf.id" class="shelf">

          <div class="shelf-name font-heading">
            <div class="read-container">
              {{ shelf.name }}
            </div>
          </div>

          <div class="read-container">
            <div v-for="brand in shelf.brands" :key="brand.id" class="brand">
              <brand-selector v-model="selected_brands" :brand_id="brand"/>
            </div>
          </div>

        </div>

        <div class="footer text-center py-4">
          <button class="btn btn-lg btn-primary uppercase"
                  @click="submit">
            VALIDER
          </button>
        </div>

      </div>

    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layout/AppLayout";
import ShopHead from "@/components/ShopHead";
import BrandSelector from "@/components/BrandSelector";
import {mapActions, mapGetters} from "vuex";
import products from "@/mixins/products";
export default {
  name: "ArmoireBrands",
  components: {BrandSelector, ShopHead, AppLayout},
  mixins: [ products ],

  computed: {

    ...mapGetters(['selectedBrandIds']),

    selected_brands: {
      get(){
        return this.selectedBrandIds ?? [];
      },
      set(brand_ids){
        this.selectBrands(brand_ids);
      },
    },
  },

  methods: {

    ...mapActions(['selectBrands']),

    submit(){
      this.$router.push({name: 'armoire'});
    }

  },

  mounted(){

    // if(this.selectedBrandIds.length < 1){
    //   //console.log("Brands",this.select_all_brands)
    //   setTimeout(() => this.selectBrands(this.select_all_brands), 500);
    // }
  }

}
</script>

<style lang="scss" scoped>
.armoire-brands{
  font-size: 20px;
  color: white;
}
.container {
  margin-top: 16px;
  margin-left: 7px;
}
.shelf{
  background: white;
  padding: 1px 0;
}
.shelf-name{
  background: #231f20;
  color: white;

  .read-container{
    padding: .5rem 1rem;
  }
}
.brand{
  margin: 1.5rem 0;
  padding: 0 1rem;
  color: #231f20;
}
</style>
