<template>
  <div class="container" v-if="has_brand_selection && type !== 'final-list'">
    <div class="sub_container">
      <div class="title">
        <p class="font-heading">{{ title }}</p>
        <span>{{ total }}</span>
      </div>

      <slider-container v-for="brand in selected_brands_in_shelf"
                        :key="brand.id"
                        :brand="brand" />
    </div>
  </div>
  <div class="container" v-else-if="has_brand_selection  && type == 'final-list' && total !== 0">
    <div class="sub_container">
      <div class="title">
        <p class="font-heading">{{ title }}</p>
        <span>{{ total }}</span>
      </div>

      <slider-container v-for="brand in selected_brands_in_shelf"
                        :key="brand.id"
                        :brand="brand"
                        type="final-list" />
    </div>
  </div>
</template>

<script>
import SliderContainer from "./SliderContainer.vue";
import products from "@/mixins/products";
import {mapGetters} from "vuex";

export default {
  props: ['title', 'brandIds', 'type'],
  mixins: [products],

  components: {
    SliderContainer,
  },

  computed: {

    ...mapGetters(['productCount', 'selectedBrandIds']),

    brands_in_shelf(){
      return this.brandsByIds(this.brandIds);
    },

    selected_brands_in_shelf(){
      return this.brands_in_shelf.filter( b => this.selectedBrandIds.indexOf( b.id ) >= 0 );
    },

    title_splitted(){
      return this.title.split(' ');
    },

    total(){
      return this.brands_in_shelf
          .map((brand) => this.productsOfBrand(brand))
          .reduce((products, product_group) => products.concat(product_group), [])
          .reduce((total, p) => total + this.productCount( p.id ), 0);
    },

    has_brand_selection(){
      return this.brandIds.filter( b => this.selectedBrandIds.indexOf(b) >= 0 ).length > 0;
    },

  },

};
</script>

<style scoped>
.container {
  margin-top: 16px;
  margin-left: 7px;
}
.title {
  background-color: #231f20;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.title p {
  font-size: 20px;
  color: white;
  font-weight: 600;
}

.title span {

  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecca00;
  color: #231f20;
  font-size: 22px;
  border-radius: 50%;
  margin-right: 16px;
}
.sliderContainer {
  margin-bottom: 4px;
}
</style>
