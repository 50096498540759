<template>
  <div class="brand-selector">

    <div class="brand-name">
      <template v-if="current_brand.logo">

          <img :src="logo_url" class="brand-logo" :alt="current_brand.name"/>
          <h6 class="ml-3">{{ current_brand.name }}</h6>

      </template>
      <template v-else>
        {{ current_brand.name }}
      </template>
    </div>

    <div class="options">
      <label>
        <input type="radio" v-model="brand_is_selected" value="yes">
        <span class="yes">
        <i class="fa-solid fa-check-circle"></i> Oui
      </span>
      </label>
      <label>
        <input type="radio" v-model="brand_is_selected" value="no">
        <span class="no">
        <i class="fa-solid fa-times-circle"></i> Non
      </span>
      </label>
    </div>
  </div>
</template>

<script>

import products from "@/mixins/products";
import data from "@/config/api_end_points.js";

export default {
  name: "BrandSelector",
  props: ['brand_id', 'modelValue'],
  mixins: [products],

  data(){
    return {
      brand_is_selected: null,
      selectedBrands: []
    }
  },


  computed: {
    current_brand(){
      return this.brandsByIds( [this.brand_id] )[0] ?? null;
    },
    logo_url(){
      return this.current_brand.logo ? data.brand_image_base_url + '/' + encodeURIComponent(this.current_brand.logo).replace('%2F', '/') : '/images/products/placeholder.png;';
    },

  },

  watch: {
    brand_is_selected(selected){
      const idx = this.selectedBrands.indexOf( this.brand_id );
      if( selected === 'yes' ){
        if( idx < 0 ){
          this.selectedBrands.push( this.brand_id );
        }
      }else if( selected === 'no' ){
        if( idx >= 0 ){
          this.selectedBrands.splice( idx, 1 );
        }
      }
      this.$emit('update:modelValue', this.selectedBrands);
    }
  },

  mounted() {
    this.selectedBrands = this.modelValue ?? [];
    this.brand_is_selected = this.selectedBrands.indexOf( this.brand_id ) >= 0 ? 'yes' : 'no';
  }

}
</script>

<style lang="scss" scoped>

.brand-selector{
  display: flex;
  align-items: center;
}

.brand-name{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  img{
    max-width: 160px;
    max-height: 50px;
  }
}

.options{
  display: flex;
}

label{
  display: inline-flex;

  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;

  i{
    margin-right: .6rem;
  }

  span{
    display: block;
    padding: 6px 10px;
  }

  .yes{
    border-radius: 3rem 0 0 3rem;
    margin-right: 1px;
  }

  .no{
    border-radius: 0 3rem 3rem 0;
  }

}

input{
  display: none;


  &:checked{
    + span{
      &.yes{
        background: #77be4e;
        color: white;
      }
      &.no{
        background: #f16164;
        color: white;
      }
    }
  }
}

.yes{
  color: #231f20;
  background: lighten(#77be4e, 40%);
}
.no{
  color: #231f20;
  background: lighten(#f16164, 30%);
}

</style>
