import { createStore } from 'vuex';
import storage from "@/mixins/storage";

const state = {
    shop: {
        name: '',
        address: '',
        city: '',
        zipcode: '',
        month: '',
        year: '',
        email: '',
    },
    products: [],
    selected_brand_ids: [],
    source_data: { brands: [], products: [], shelves: []}
    // return_box_count: 0,
};

const mutations = {

    // setReturnBoxCount(state, box_count) {
    //     box_count = parseInt(box_count);
    //     box_count = box_count < 0 ? 0 : box_count;
    //     state.return_box_count = box_count;
    // },

    changeProductCount(state, {product_id, change}) {

         let product = state.products.find((p) => p.id === product_id);

         if(!product){
             product = {id: product_id, count: change};
             state.products.push(product);
         }else{
             product.count += change;
         }

         if( product.count <= 0 ){
             //product.count = 0;
             var index = state.products.indexOf(product)
             if (index > -1) {
                state.products.splice(index, 1);
            }
         }

         storage.methods.saveProductsData( state.products );
    },

    replaceProducts(state, products) {
        state.products = products;
        storage.methods.saveProductsData( state.products );
    },

    changeShopInfo(state, shop) {
        state.shop = shop;
        storage.methods.saveShopData( state.shop );
    },

    resetAll(state) {

        state.shop = {
            name: '',
            address: '',
            city: '',
            zipcode: '',
            month: '',
            year: '',
            email: '',
        };

        state.products = [];
        // state.return_box_count = 0;

        storage.methods.saveShopData( state.shop );
        storage.methods.saveProductsData( state.products );
        storage.methods.saveSelectedBrandsData( state.selected_brand_ids );
    },

    resetProducts(state) {
        state.products = [];
        storage.methods.saveProductsData( state.products );
    },

    selectBrands(state, brand_ids){
        state.selected_brand_ids = brand_ids;
        storage.methods.saveSelectedBrandsData( brand_ids );
    },

    setBrands(state, brands){
        state.source_data.brands = brands;
    },

    setProducts(state, products){
        state.source_data.products = products;
    },

    setShelves(state, shelves){
        state.source_data.shelves = shelves;
    }

};


const getters = {

    // returnBoxCount: (state) => {
    //     return state.return_box_count;
    // },

    productCount: (state) => (product_id) => {
        console.log( product_id );
        return state.products.find((p) => p.id === product_id)?.count ?? 0;
    },
    shop: (state) => {
        return state.shop;
    },
    allProducts: (state) => {
        return state.products;
    },

    selectedBrandIds: (state) => {
        return state.selected_brand_ids ?? [];
    },

    getBrands: (state) => {
        return state.source_data.brands;
    },

    getProducts: (state) => {
        return state.source_data.products;
    },

    getShelves: (state) => {
        return state.source_data.shelves;
    },

    getAllowedProducts: (state) => {
        return state.source_data.shelves.reduce((old, value) => old.concat(value.products.reduce((o,v ) => o.concat(v), [] )), [])
    }
};


const actions = {

    changeProductCount: ({commit}, {product_id, change}) => {
        commit('changeProductCount', {product_id, change});
    },

    replaceProducts: ({commit}, products) => {
        commit('replaceProducts', products);
    },

    changeShopInfo: ({commit}, shop) => {
        commit('changeShopInfo', shop);
    },

    resetAll: ({commit}) => {
        commit('resetAll');
    },

    resetProducts: ({commit}) => {
        commit('resetProducts');
    },

    selectBrands: ({commit}, brand_ids) => {
        commit('selectBrands', brand_ids);
    },

    setBrands: ({commit}, brands) => {
        commit('setBrands', brands)
    },

    setProducts: ({commit}, products) => {
        commit('setProducts', products)
    },

    setShelves: ({commit}, shelves) => {
        commit('setShelves', shelves);
    }


    // setReturnBoxCount: ({commit}, box_count) => {
    //     commit('setReturnBoxCount', box_count);
    // },
};


export default createStore({
    state,
    getters,
    actions,
    mutations,
});
