<template>
  <app-layout>
    <div class="armoire-filled">

      <shop-head>
        Merci de sélectionner les produits que vous allez nous retourner
      </shop-head>

      <item-carousel-container v-for="(shelf, idx) in shelves"
                               :key="idx"
                               :title="shelf.name"
                               :brand-ids="shelf.brands"/>

      <div class="footer text-center py-4">
        <button class="btn btn-lg btn-primary uppercase"
                :disabled="is_busy || ! total"
                @click="submit">
          {{ is_busy ? 'Envoi en cours...' : 'Envoyer mes retours' }}
        </button>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layout/AppLayout";
import ItemCarouselContainer from "@/components/ItemCarouselContainer";
import products from "@/mixins/products";
import {mapActions, mapGetters} from "vuex";
import ShopHead from "@/components/ShopHead";

export default {
  components: {ShopHead, AppLayout, ItemCarouselContainer },
  mixins: [products],


  data(){
    return {
      is_busy: false,
    }
  },

  computed: {
    ...mapGetters([
      'shop',
      'productCount',
      'selectedBrandIds',
      'allProducts',
    ]),

    pharmacy_is_valid(){
      // if( ! this.shop.name ) return false;
      // if( ! this.shop.address ) return false;
      // if( ! this.shop.city ) return false;
      // if( ! this.shop.zipcode ) return false;
      // if( ! this.shop.month ) return false;
      // if( ! this.shop.year ) return false;
      if( ! this.shop.email ) return false;
      return true;
    },

    total(){
      return this.allProducts.reduce((c, p) => c + (p?.count ?? 0), 0);
    },

  },

  methods: {

    ...mapActions([
        'resetProducts'
    ]),

    async submit(){

      // if( !confirm('Êtes-vous sûr de soumettre?') ) return;

      // this.is_busy = true;

      this.$router.push({ name: 'selected' });
      // try{
      //   const res = await axios.post('/send.php', {
      //     shop: this.shop,
      //     shelves: this.processProducts(),
      //   })
      //   if( (res.data?.error ?? -1) === 0 ){
      //     this.resetProducts();
      //     await this.$router.push({ name: 'thank-you' });
      //   }else{
      //     this.error();
      //     //console.log("Not in send function!")
      //   }
      // }catch (e) {
      //   this.error();
      //   // console.log("Not in send function!")
      // }
      // this.is_busy = false;
    },

    error(){
      alert('Oops..\nQuelque chose de mal s\'est produit, veuillez réessayer.');
    },

    processProducts(){
      return this.shelves.map((shelf) => {
        const selected_brand_ids_on_shelf = shelf.brands.filter( bid => this.selectedBrandIds.indexOf(bid) >= 0 );
        const brands = this.brandsByIds( selected_brand_ids_on_shelf );
        return {
          shelf: {
            brands: selected_brand_ids_on_shelf,
            name: shelf.name,
          },
          brands: brands.map((brand) => {
            const products = this.productsOfBrand( brand );
            return {
              brand,
              products: products.map(product => {
                return {
                  ...product,
                  count: this.productCount(product.id)
                }
              }),
            };
          }),
        };
      })
    },

  },

  mounted(){
    if( !this.pharmacy_is_valid ){
      this.$router.push({name: 'home'});
    }
  }
};


</script>

<style lang="scss" scoped>
.armoire-filled {
  background-color: #d2dfd8;
}

.sending{
  text-align: center;
  font-size: 1.2rem;
  padding: 1rem;
}
</style>
