<template>
    <app-layout>
        <div class="armoire-filled">
            <shop-head>
              Merci de remplir les marques que vous allez nous retourner
            </shop-head>

            <h1>Veuillez confirmer le retour des produits suivants</h1>
            <item-carousel-container v-for="(shelf, idx) in shelves"
                                :key="idx"
                                :title="shelf.name"
                                :brand-ids="shelf.brands"
                                type="final-list"/>

            <div class="read-container">
                <div class="input-box">
                    <label for="return_box_count">Combien de cartons nous retournez-vous ?</label>
                    <input id="return_box_count" type="number" min="0" v-model="return_box_count"/>
                </div>
            </div>

            <div class="footer text-center py-4">
                <button class="btn btn-lg btn-primary uppercase"
                        :disabled="is_busy"
                        @click="submit">
                {{ is_busy ? 'Envoi en cours...' : 'ENVOYER MON RELEVÉ' }}
                </button>
            </div>
        </div>

    </app-layout>
</template>

<script>
import AppLayout from "@/layout/AppLayout";
import ShopHead from "@/components/ShopHead";
import ItemCarouselContainer from "@/components/ItemCarouselContainer";
import {mapActions, mapGetters} from "vuex";
import products from "@/mixins/products";
import axios from 'axios'
export default {
    name: "ArmoireSelected",
    components: {ShopHead, AppLayout, ItemCarouselContainer},
    mixins: [products],

    data(){
        return {
            is_busy: false,
            return_box_count: 0,
        }
    },

    computed: {
        ...mapGetters([
        'shop',
        'productCount',
        'selectedBrandIds',
        ]),
    },

    methods:{
        ...mapActions([
            'resetProducts'
        ]),

        async submit(){


            if( this.return_box_count <= 0 ){
                alert('Vous devez donner le nombre de boîtes que vous retournez ?')
                return;
            }

            if( !confirm('Êtes-vous sûr de soumettre?') ) return;

            this.is_busy = true;

            try {

                const send_url = window.location.host.match('localhost')
                    ? 'http://localhost:8088/send.php'
                    : '/send.php';

                const res = await axios.post(send_url, {
                    shop: this.shop,
                    shelves: this.processProducts(),
                    return_box_count: this.return_box_count,
                })
                if ((res.data?.error ?? -1) === 0) {
                    // console.log("Moving to thank you page!")
                    this.resetProducts();
                    await this.$router.push({name: 'thank-you'});
                } else {
                    this.error();
                    console.log("Not in send function 1 !")
                }
            } catch (e) {
                this.error();
                console.log("Not in send function 2!")
            }
            this.is_busy = false;
        },
        error(){
            alert('Oops..\nQuelque chose de mal s\'est produit, veuillez réessayer.');
        },

        processProducts(){
            return this.shelves.map((shelf) => {
                const selected_brand_ids_on_shelf = shelf.brands.filter( bid => this.selectedBrandIds.indexOf(bid) >= 0 );
                const brands = this.brandsByIds( selected_brand_ids_on_shelf );
                return {
                shelf: {
                    brands: selected_brand_ids_on_shelf,
                    name: shelf.name,
                },
                brands: brands.map((brand) => {
                    const products = this.productsOfBrand( brand );
                    return {
                    brand,
                    products: products.map(product => {
                        return {
                        ...product,
                        count: this.productCount(product.id)
                        }
                    }),
                    };
                }),
                };
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    h1{
        color: black;
        text-align: center;
        font-family: 'Recoleta SemiBold', serif;
        margin-top: 1rem;
    }
    .input-box{
        margin-top: 2rem;
        align-items: center;

        input{
            max-width: 120px;
            margin-left: 1rem;
        }
    }
</style>
