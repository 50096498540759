<template>
  <div class="slider-container" v-if="type !== 'final-list'">
    <div class="slider-head">
      <p class="slider-head-name">
        {{ brand.name }}
<!--        <span class="part" v-for="(text, index) in brand_name_splitted" :key="index">-->
<!--          <span class="letter" v-for="(letter, index) in text" :key="index">{{ letter }}</span>-->
<!--        </span>-->
      </p>
    </div>
    <item-carousel class="item-carousel" :products="brand_products" :type="type"/>
  </div>
  <div class="slider-container" v-else-if="type == 'final-list' && removed_brands.length > 0">
    <div class="slider-head">
      <p class="slider-head-name">
        {{ brand.name }}
<!--        <span class="part" v-for="(text, index) in brand_name_splitted" :key="index">-->
<!--          <span class="letter" v-for="(letter, index) in text" :key="index">{{ letter }}</span>-->
<!--        </span>-->
      </p>
    </div>
    <item-carousel class="item-carousel" :products="brand_products" :type="type"/>
  </div>
</template>

<script>
import itemCarousel from "@/components/ItemCarousel";
import products from "@/mixins/products";
import { mapGetters} from "vuex";
export default {
  components: { itemCarousel },
  mixins: [products],

  props: ['brand', 'type'],

  computed: {

    ...mapGetters([
        'selectedBrandIds',
        'allProducts'
    ]),

    brand_products(){
      return this.productsOfBrand(this.brand);
    },

    removed_brands(){
       return this.productsOfBrand(this.selectedBrandIds.filter(b => b === this.brand.id)[0]).filter(p => this.allProducts.filter(a => a.id == p.id)[0])
    },

    brand_name_splitted(){
      return this.brand.name.split(' ');
    },
  },

  mounted(){
    console.log("Brands", this.productsOfBrand(this.selectedBrandIds.filter(b => b === this.brand.id)[0]).filter(p => this.allProducts.filter(a => a.id == p.id)[0])  )
    console.log(this.allProducts)
    // .filter(p => )
  }
};
</script>

<style lang="scss" scoped>
.slider-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-bottom: .3rem;
}
.item-carousel {
  flex-grow: 1;
}
.slider-head {
  padding: .5rem .8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #5b8d77;
  width: auto;
  flex: 0 0 1rem;

  @media screen and (min-width: 700px) {
    width: 150px;
    flex: 0 0 150px;
    padding: .3rem;
  }
}

.slider-head-name {

  font-weight: 600;
  font-size: 1.2rem;
  color: white;
  // display: flex;
  writing-mode: vertical-rl;
  // text-orientation: upright;
  text-transform: uppercase;

  @media screen and (min-width: 700px) {
    font-size: 1rem;
    writing-mode: horizontal-tb;
    // word-break: break-all;
    text-orientation: mixed;
  }

  .part{
    width: 1rem;
    margin: 0 .1rem;
  }
}
</style>
