import {mapGetters} from "vuex";

export default {
    methods: {
        getSavedShopData(){
            const shop = window.sessionStorage?.getItem("shop-data");
            const default_shop_data = {
                name: '',
                zipcode: '',
                day: '',
                month: '',
                year: '',
            };

            if( ! shop ){
                return default_shop_data;
            }

            return Object.assign({}, default_shop_data, JSON.parse(shop));
        },

        saveShopData(shop){
            window.sessionStorage?.setItem("shop-data", JSON.stringify( shop ));
        },

        getSavedProductsData(){
            const products = window.sessionStorage?.getItem("product-data");
            if( ! products ){
                console.log("Setting Default to 2")
                return this.select_all_products;
            }
            return JSON.parse( products );
        },

        saveProductsData(products){
            window.sessionStorage?.setItem("product-data", JSON.stringify( products ));
        },

        getSavedSelectedBrandsData(){
            const brands = window.sessionStorage?.getItem("selected-brands-data");
            if( ! brands ){
                return this.select_all_brands;
            }
            return JSON.parse( brands );
        },

        saveSelectedBrandsData(selected_brands) {
            window.sessionStorage?.setItem("selected-brands-data", JSON.stringify( selected_brands ));
        }
    },
    computed:{

        ...mapGetters(['getShelves', 'getAllowedProducts']),
        shelves(){
            return this.getShelves;
        },
        products(){
            return this.getAllowedProducts;
        },
        select_all_brands(){
            //return products.computed.
            return this.shelves.reduce( (old, value) => old.concat(value.brands.reduce( (o,v) => o.concat(v), [])), []);
        },
        select_all_products(){
            return this.products.map( p => ({
                id: p.id,
                count: 2
            }))
        }
    }

}
