// import products from "@/data/products.json";
// import brands from "@/data/brands.json";
// import shelves from "@/data/shelves.json";


//const applicable_brands = brands.filter(b => [6, 7, 5, 2, 9, 11].indexOf( b.id ) >= 0);
//const applicable_shelves = shelves.filter(s => applicable_brands.find( b => s.brands.indexOf( b.id ) >= 0 ) );
import axios from "axios";
import {mapGetters} from "vuex";
import data from "../config/api_end_points.js"

export default {

    computed:{
        ...mapGetters(['getBrands', 'getProducts', 'getShelves']),
        brands(){
            return this.getBrands;
        },
        shelves(){
            return this.getShelves;
        },
        products(){
            return this.getProducts;
        },

    },

    methods: {
        productsOfBrand(brand){
            const brand_id = typeof brand === 'object' ? (brand.id ?? null) : brand;
            return this.products.filter((p) => p.brand_id === brand_id);
        },
        brandsByIds(ids){
            return this.brands.filter((b) => ids.indexOf(b.id) >= 0);
        },
        async initialiseBrands(){
            return await axios.get(`${data.api}/api/brands`)
                .then(response => {
                    // console.log("Brands Inside", response.data, this.brands)
                    // //this.brands = response.data;
                    // response.data.map(b => this.brands.push(b))
                    // console.log();
                    return response.data;
                });
        },
        async initialiseProducts(){
            return await axios.get(`${data.api}/api/products`)
                .then(response => {
                    return response.data;
                })
        },
        async initialiseShelves(){
            return await axios.get(`${data.api}/api/shelves/returns`)
                .then(response => {
                    const shelves = response.data.shelves;

                    const formatedShelves = shelves.map(s => ({
                        name: s.name,
                        brands: s.brands.map(b => b.brand_id),
                        products: s.brands.map(b => b.products.map(p => p))
                    }))

                    return formatedShelves;
                })
        }
    },

}
