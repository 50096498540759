<template>
  <div class="app">
  <router-view v-if="is_ready"/>
  </div>
</template>

<script>

import storage from "@/mixins/storage";
import products from "@/mixins/products"
import {mapActions} from "vuex";

export default {
  name: "App",
  components: {
  },
  mixins: [storage, products],
  data(){
    return{
      is_ready: false
    }
  },
  methods: {
    ...mapActions(["changeShopInfo", "replaceProducts", 'selectBrands', 'setBrands', 'setProducts', 'setShelves']),
  },

  async mounted() {
    this.setBrands(await this.initialiseBrands())
    this.setProducts(await this.initialiseProducts())
    this.setShelves(await this.initialiseShelves())
    this.changeShopInfo( this.getSavedShopData() );
    this.replaceProducts( this.getSavedProductsData() );
    this.selectBrands( this.getSavedSelectedBrandsData() );
    this.is_ready = true;
    console.log("Brands", this.brands)
  }

};
</script>

<style scoped>

</style>
