<template>
  <div class="item">
    <div class="image" :style="`background-image:url('${image_url}')`">
      <div class="count">{{ product_count }}</div>
      <div class="actions" v-if="type !== 'final-list'">
        <button type="button" class="minus" @click.prevent="changeCount( -1 )">
          <i class="fa-solid fa-minus"></i>
        </button>
        <button type="button" class="plus" @click.prevent="changeCount( 1 )">
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="name">{{ product.name }}</div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import data from "../config/api_end_points.js";

export default {
  props: ['product', 'type'],

  data() {
  },

  computed: {

    ...mapGetters([
      'productCount',
    ]),

    product_count(){
      return this.productCount( this.product?.id ?? null );
    },

    image_url(){
      return this.product.image ? data.product_image_base_url + '/' + encodeURIComponent(this.product.image).replace('%2F', '/') : '/images/products/placeholder.png;';
    }

  },

  methods: {

    ...mapActions([
      'changeProductCount',
    ]),

    changeCount(change){
      this.changeProductCount({product_id: this.product.id, change});
    }
  },

  mounted(){
    console.log(this.image_url)
  }

 
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  position: relative;
}

.image{
  width: 100%;
  height: 200px;
  background: white no-repeat center;
  background-size: cover;
}

.name{
  color: #231f20;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: .3rem;
  text-align: center;
}

.minus, .plus{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  vertical-align: middle;
  color: white;
  border: none;
  box-shadow: rgba(black, 0.1) 0 2px 3px;
  cursor: pointer;
  font-size: 1.2rem;
  z-index: 1;
}

.minus{
  left: 0;
  background-color: #f16164;
  border-radius: 0 99px 99px 0;
  padding: .9rem 1.2rem .9rem .9rem;
}

.plus{
  right: 0;
  background-color: #77be4e;
  border-radius: 99px 0 0 99px;
  padding: .9rem .9rem .9rem 1.2rem;
}

.count{
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: #231f20;
  font-size: 28px;

  font-weight: 600;
  background-color: #ecca00;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
