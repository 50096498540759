import { createWebHistory, createRouter } from "vue-router";
import HomePage from "./pages/HomePage.vue";
import ArmoireFilled from "./pages/ArmoireFilled.vue";
import ThankYou from "./pages/ThankYou.vue";
import ArmoireBrands from "@/pages/ArmoireBrands";
import ArmoireTerms from "@/pages/ArmoireTerms";
import ArmoireSelected from "@/pages/ArmoireSelected";

const routes = [
  {
    path: "/",
    component: HomePage,
    name: "home",
  },
  {
    path: "/brands",
    component: ArmoireBrands,
    name: "brands",
  },
  {
    path: "/armoire",
    component: ArmoireFilled,
    name: "armoire",
  },
  {
    path: "/thank-you",
    component: ThankYou,
    name: "thank-you",
  },
  {
    path: "/tnc",
    component: ArmoireTerms,
    name: "terms-condition",
  },
  {
    path: "/selected",
    component: ArmoireSelected,
    name: "selected",
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { top: 0 };
  }
});

export default router;
