<template>
  <div class="app-layout">
    <header></header>

    <div class="app-layout-body">
      <slot />
    </div>

    <footer class=""></footer>
  </div>
</template>

<script>
export default {
  name: "AppLayout",
};
</script>

<style scoped>
.app-layout {
  background-color: #d2dfd8;
  background-size: cover;
}
.app-layout-body {
  min-height: 100vh;
}
</style>
