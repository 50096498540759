<template>
  <div class="form-group">
    <div class="input-group">
      <div class="input-box nom">
        <label for="pharmacy-name"> NOM DE LA PHARMACIE </label>
        <input type="text" id="pharmacy-name" placeholder="Pharmacie" v-model="shop.name" @keyup="update" />
      </div>
      <div class="input-box">
        <label for="email">
          EMAIL <span>*</span>
        </label>
        <input id="email"
               type="email"
               v-model="shop.email"
               placeholder="E-mail"
               @keyup="update()"
               @change="validate()"
               required/>
        <span class="error" v-if="hasError('email')"><small>{{ this.errors['email'] }}</small></span>
      </div>
      <div class="input-box">
        <label for="address">ADRESSE</label>
        <textarea id="address" placeholder="Adresse" v-model="shop.address" @keyup="update"/>
      </div>
      <div class="input-box">
        <label for="city">VILLE</label>
        <input id="city" v-model="shop.city" @keyup="update" placeholder="Nom de votre ville" />
      </div>
      <div class="input-box">
        <label for="zipcode">CODE POSTAL</label>
        <input type="number" id="zipcode" placeholder="xxxxxx" v-model="shop.zipcode" @keyup="update" />
      </div>
      <div class="dates">
        <div class="input-box">
          <label for="month">JOUR</label>
          <select id="month" v-model="shop.day" @change="update">
            <option v-for="day in 31"
                    :key="day"
                    :value="day">{{ day }}</option>
          </select>
        </div>
        <div class="input-box month">
          <label for="month">MOIS</label>
          <select id="month" v-model="shop.month" @change="update">
            <option v-for="(month) in months"
                    :key="month"
                    :value="month">{{ month }}</option>
          </select>
        </div>
        <div class="input-box">
          <label for="year">ANNÉE</label>
          <select id="year" v-model="shop.year" @change="update">
            <option v-for="year in years"
                    :key="year"
                    :value="year">{{ year }}</option>
          </select>
        </div>
      </div>
    </div>

    <button class="btn btn-lg btn-primary"
            @click="next()">
      COMMENCER
    </button>

  </div>
</template>

<script>
import months from "@/data/months.json";
import {mapActions, mapGetters} from "vuex";
export default {
  components: {},

  data(){
    return {

      errors: {},

      shop: {
        name: '',
        address: '',
        city: '',
        zipcode: '',
        month: '',
        year: '',
        email: '',
      },
      months
    }
  },

  computed: {

    ...mapGetters({
      store_shop: "shop",
    }),

    current_year(){
      return (new Date()).getFullYear()
    },

    current_month(){
      return (new Date()).getMonth()
    },

    current_day(){
      return (new Date()).getDate()
    },

    years(){
      const years = [];
      for( let i = -1; i <= 1; i++ ){
        years.push( this.current_year + i );
      }
      return years;
    },

  },

  methods: {
    ...mapActions(['changeShopInfo']),

    update(){
      this.changeShopInfo( this.shop );
    },

    next(){
      if( this.validate() ){
        this.$router.push({name: 'terms-condition'});
      }else{
        alert('Veuillez fournir les détails correctement.');
      }
    },

    validate(){

      this.errors['email'] = false;

      if( ! this.shop.email ){
        this.errors['email'] = 'L\'e-mail est obligatoire';
      }
      if( ! this.shop.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ){
        this.errors['email'] = 'L\'email n\'est pas valide';
      }

      for (const key in this.errors){
        if( this.errors[ key ] ){
          return false;
        }
      }

      return  true;
    },

    hasError( field ){
      return this.errors[ field ] ?? false;
    },

  },

  mounted() {

    this.shop.name = this.store_shop.name;
    this.shop.address = this.store_shop.address;
    this.shop.city = this.store_shop.city;
    this.shop.zipcode = this.store_shop.zipcode;
    this.shop.day = this.store_shop.day;
    this.shop.month = this.store_shop.month;
    this.shop.year = this.store_shop.year;
    this.shop.email = this.store_shop.email;

    if( ! this.shop.day ){
      this.shop.day = this.current_day;
    }
    if( ! this.shop.month ){
      this.shop.month = this.months[ this.current_month ];
    }
    if( ! this.shop.year ){
      this.shop.year = this.current_year;
    }

    this.update();

  }

};
</script>

<style lang="scss" scoped>

</style>
