import { createApp } from 'vue'
import router from '@/routes'
import App from '@/App.vue'
import store from '@/store/store';

import Vue3Lottie from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';

import '@/styles/app.scss';


createApp(App)
    .use(router)
    .use(store)
    .use(Vue3Lottie)
    .mount('#app');
